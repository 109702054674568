import logo from './assets/assets/profile-pic.jpeg';
import './App.css';

function App() {
  return (
    <>


      <i className="bi bi-list mobile-nav-toggle d-xl-none"></i>

      <header id="header">
        <div className="d-flex flex-column">

          <div className="profile">
            <img src={logo} alt="" className="img-fluid rounded-circle" />
            <h1 className="text-light"><a href="index.html">Sandip Kundu</a></h1>
            <p className="text-light text-capitalize text-center">Apps that are made to express</p>
            <div className="social-links mt-3 text-center">
              {/* <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a> */}
              {/* <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a> */}
              <a href="https://github.com/SazzDS" className="instagram"><i className="bx bxl-github"></i></a>
              <a href="mailto:live:.cid.fb49745d46369b4" className="google-plus"><i className="bx bxl-skype"></i></a>
              <a href="https://www.linkedin.com/in/sandip-kundu-86b522191/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
            </div>
          </div>

          <nav id="navbar" className="nav-menu navbar">
            <ul>
              <li><a href="#hero" className="nav-link scrollto active"><i className="bx bx-home"></i> <span>Home</span></a></li>
              <li><a href="#about" className="nav-link scrollto"><i className="bx bx-user"></i> <span>About</span></a></li>
              <li><a href="#resume" className="nav-link scrollto"><i className="bx bx-file-blank"></i> <span>Resume</span></a></li>
              {/* <li><a href="#portfolio" className="nav-link scrollto"><i className="bx bx-book-content"></i> <span>Portfolio</span></a></li> */}
              <li><a href="#services" className="nav-link scrollto"><i className="bx bx-server"></i> <span>Services</span></a></li>
              <li><a href="#contact" className="nav-link scrollto"><i className="bx bx-envelope"></i> <span>Contact</span></a></li>
            </ul>
          </nav>
        </div>
      </header>

      <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
        <div className="hero-container" data-aos="fade-in">
          <h1 className="text-left mr-0">Sandip Kundu</h1>
          <p className="text-left mr-0">I'm Software Engineer | Software Architect<span className="typed" data-typed-items="Designer, Developer, Freelancer"></span></p>
        </div>
      </section>

      <main id="main">

        <section id="about" className="about">
          <div className="container">

            <div className="section-title">
              <h2>About</h2>
              <p>I am versatile, hard worker and a very good team player. I started my career from 2015. I have around 8+ years of experience on Full Stack Developer AS PHP, React.JS, Node.JS & Flutter.</p>
            </div>

            <div className="row">
              <div className="col-lg-4" data-aos="fade-right">
                <img src={logo} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                <h3> UI/UX Designer | Software Engineer | Software Architect </h3>
                <p className="fst-italic">Crafting apps to perfection</p>
                <div className="row">
                  <div className="col-lg-6">
                    <ul>
                      <li><i className="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>28 May 1994</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong> <span>www.saasproai.com</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+91 863 7054 652</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>Kolkata, IN</span></li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul>
                      <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong> <span>30</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Bachelor in Computer Science</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>sandip@saasproai.com</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li>
                    </ul>
                  </div>
                </div>
                {/* <p>
              Officiis eligendi itaque labore et dolorum mollitia officiis optio vero. Quisquam sunt adipisci omnis et ut. Nulla accusantium dolor incidunt officia tempore. Et eius omnis.
              Cupiditate ut dicta maxime officiis quidem quia. Sed et consectetur qui quia repellendus itaque neque. Aliquid amet quidem ut quaerat cupiditate. Ab et eum qui repellendus omnis culpa magni laudantium dolores.
            </p> */}
              </div>
            </div>

          </div>
        </section>

        <section id="facts" className="facts">
          <div className="container">

            <div className="section-title">
              <h2>Facts</h2>
              {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
            </div>

            <div className="row no-gutters">

              <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up">
                <div className="count-box">
                  <i className="bi bi-emoji-smile"></i>
                  <span data-purecounter-start="0" data-purecounter-end="10000" data-purecounter-duration="1" className="purecounter"></span>+
                  <p><strong>Happy Clients</strong></p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="100">
                <div className="count-box">
                  <i className="bi bi-journal-richtext"></i>
                  <span data-purecounter-start="0" data-purecounter-end="94" data-purecounter-duration="1" className="purecounter"></span>
                  <p><strong>Projects</strong></p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="200">
                <div className="count-box">
                  <i className="bi bi-headset"></i>
                  <span data-purecounter-start="0" data-purecounter-end="98" data-purecounter-duration="1" className="purecounter"></span>
                  <p><strong>Hours Of Support</strong></p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="300">
                <div className="count-box">
                  <i className="bi bi-people"></i>
                  <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter"></span>
                  <p><strong>Hard Workers</strong></p>
                </div>
              </div>

            </div>

          </div>
        </section>

        <section id="skills" className="skills section-bg">
          <div className="container">

            <div className="section-title">
              <h2>Skills</h2>
              <p></p>
            </div>

            <div className="row skills-content">

              <div className="col-lg-6" data-aos="fade-up">

                <div className="progress">
                  <span className="skill">HTML <i className="val">99%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">CSS <i className="val">97%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="97" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

<div className="progress">
  <span className="skill">JavaScript <i className="val">99%</i></span>
  <div className="progress-bar-wrap">
    <div className="progress-bar" role="progressbar" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

<div className="progress">
  <span className="skill">JQuery <i className="val">99%</i></span>
  <div className="progress-bar-wrap">
    <div className="progress-bar" role="progressbar" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

<div className="progress">
  <span className="skill">Next Js <i className="val">97%</i></span>
  <div className="progress-bar-wrap">
    <div className="progress-bar" role="progressbar" aria-valuenow="97" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

<div className="progress">
  <span className="skill">Java ( Android ) <i className="val">50%</i></span>
  <div className="progress-bar-wrap">
    <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

<div className="progress">
  <span className="skill">Kotlin ( Android ) <i className="val">09%</i></span>
  <div className="progress-bar-wrap">
    <div className="progress-bar" role="progressbar" aria-valuenow="9" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

                <div className="progress">
                  <span className="skill">Python <i className="val">95%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>


                <div className="progress">
                  <span className="skill">MySQL <i className="val">99%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">Payment Gateway <i className="val">90%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">

                <div className="progress">
                  <span className="skill">PHP <i className="val">93%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="93" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">WordPress/CMS <i className="val">90%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">React Js <i className="val">98%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="98" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">React Native <i className="val">97%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="97" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">Flutter <i className="val">95%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

<div className="progress">
  <span className="skill">Angular JS <i className="val">70%</i></span>
  <div className="progress-bar-wrap">
    <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

<div className="progress">
  <span className="skill">Node.Js <i className="val">96%</i></span>
  <div className="progress-bar-wrap">
    <div className="progress-bar" role="progressbar" aria-valuenow="07" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

<div className="progress">
  <span className="skill">Vue Js <i className="val">50%</i></span>
  <div className="progress-bar-wrap">
    <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

                <div className="progress">
                  <span className="skill">Firebase <i className="val">98%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="98" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

<div className="progress">
  <span className="skill">Cyber Security <i className="val">90%</i></span>
  <div className="progress-bar-wrap">
    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

              </div>

            </div>

          </div>
        </section>

        <section id="resume" className="resume">
          <div className="container">

            <div className="section-title">
              <h2>Resume</h2>
              <p>It is very hard to describe my whole works in my Portfolio but some projects and work description are given here.  I created 70+ User Friendly Dynamic Websites, 17+ Minor Software Projects and worked on 6 + huge Management Softwares, Both Frontend & Backend part of those projects were done by me and I also worked on minor Server Administration, Cyber Security, SaaS Product Development works.</p>
            </div>

            <div className="row">
              <div className="col-lg-6" data-aos="fade-up">
                <h3 className="resume-title">Sumary</h3>
                <div className="resume-item pb-0">
                  <h4>Sandip Kundu</h4>
                  <p><em>Innovative and deadline-driven Software Engineer with 8+ years of experience designing and developing user-centered Softwares & User Friendly Mobile Apps from initial concept to final, polished deliverable.</em></p>
                  <ul>
                    <li>Backend: Node.js, PHP, Python</li>
                    <li>Frontend: React.js, JavaScript, jQuery, HTML5, CSS3</li>
                    <li>Database: MySQL, MongoDB, Firebase</li>
                    <li>Authentication: Rest API, JWT, Session Verification, Client Storage Verification</li>
                    {/* <li>Database: MySQL, MongoDB, Firebase</li> */}
                    <li>Third-Party API & Gateway: Payment Gateways (Stripe, PayU, PayPal, PhonePe, RazorPay), Bulk SMS Gateway, Mail Server Gateway, Google Cloud Console, Facebook, and more.</li>
                    {/* <li>Backend: Node.js, PHP, Python</li> */}
                    <li>SaaS product development</li>
                    <li>Multi-vendor platforms</li>
                    <li>Subscription-based payment systems</li>
                    <li>Pro-rated calculation implementation</li>
                  </ul>
                </div>

                <h3 className="resume-title">Certified</h3>
                <div className="resume-item">
                  <h4>Full Stack Web and Hybrid Mobile App Development With React</h4>
                  <h5>May 23, 2022</h5>
                  <p><em>Udemy</em> <a href="https://www.udemy.com/certificate/UC-fb0a0f4d-5341-4d44-b5e1-3be232407b2a/">Certificate Link</a> </p>
                  {/* <p>Quia nobis sequi est occaecati aut. Repudiandae et iusto quae reiciendis et quis Eius vel ratione eius unde vitae rerum voluptates asperiores voluptatem Earum molestiae consequatur neque etlon sader mart dila</p> */}
                </div>
                <div className="resume-item">
                  <h4>MERN Stack Front To Back: Full Stack React, Redux & Node.js</h4>
                  <h5>Nov 12, 2023</h5>
                  <p><em>Udemy</em> <a href="https://www.udemy.com/certificate/UC-40cbd390-f038-4071-afa4-84ba562b3140/">Certificate Link</a> </p>
                  {/* <p>Quia nobis sequi est occaecati aut. Repudiandae et iusto quae reiciendis et quis Eius vel ratione eius unde vitae rerum voluptates asperiores voluptatem Earum molestiae consequatur neque etlon sader mart dila</p> */}
                </div>
                <div className="resume-item">
                  <h4>Build a Blockchain & Cryptocurrency | Full-Stack Edition</h4>
                  <h5>Sept 20, 2023</h5>
                  <p><em>Udemy</em> <a href="https://www.udemy.com/certificate/UC-0df90e4a-1146-4749-926d-6c0e980b771c/">Certificate Link</a> </p>
                  {/* <p>Quia nobis sequi est occaecati aut. Repudiandae et iusto quae reiciendis et quis Eius vel ratione eius unde vitae rerum voluptates asperiores voluptatem Earum molestiae consequatur neque etlon sader mart dila</p> */}
                </div>
                <h3 className="resume-title">Education</h3>
                <div className="resume-item">
                  <h4>Bachelor of Computer Science</h4>
                  <h5>2012 - 2015</h5>
                  <p><em>Gour Mahavidyalaya, Malda, IN</em></p>
                  {/* <p>Quia nobis sequi est occaecati aut. Repudiandae et iusto quae reiciendis et quis Eius vel ratione eius unde vitae rerum voluptates asperiores voluptatem Earum molestiae consequatur neque etlon sader mart dila</p> */}
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <h3 className="resume-title">Professional Experience</h3>
                <div className="resume-item">
                  <h4>Adytum Technologies (Adytuminfotech Softwares Pvt. Ltd.) :</h4>
                  <h5>1st March 2015 to 30th January 2021</h5>
                  <p><em>Software Engineer </em></p>

                  <ul>
                    <li>Lead in the design, development, and implementation of the design, backend, and production.</li>
                    <li>School Websites: ( PHP ) 
                      <br /><a href="http://www.chopragirlshighschool.org/">http://www.chopragirlshighschool.org/ ( SCRATCH )</a> 
                      <br /><a href="http://nalagolahighschool.com/">http://nalagolahighschool.com/ ( SCRATCH )</a> 
                      <br /><a href="https://brdcmalda.com/">https://brdcmalda.com/ ( SCRATCH )</a> 
                      <br /><a href="http://brijm.org/">http://brijm.org/ ( SCRATCH )</a> 
                      <br /><a href="http://dskbhm.org/">http://dskbhm.org/ ( SCRATCH )</a> 
                      <br /><a href="http://gbshighmadrasah.com/">http://gbshighmadrasah.com/ ( SCRATCH )</a> 
                      <br /><a href="http://housenagarhighmadrasah.org/">http://housenagarhighmadrasah.org/ ( SCRATCH )</a> 
                      <br /><a href="https://kamission.org/">https://kamission.org/ ( SCRATCH )</a> 
                      <br /><a href="http://mdphs.org/">http://mdphs.org/ ( SCRATCH )</a> 
                      <br /><a href="http://nalagolahighschool.com/">http://nalagolahighschool.com/ ( SCRATCH )</a> 
                      <br /><a href="https://panchagramisahighschool.com/">https://panchagramisahighschool.com/ ( SCRATCH )</a> 
                      <br /><a href="http://pakuahatanmmalda.org/">http://pakuahatanmmalda.org/ ( SCRATCH )</a> 
                      &nbsp; & So On etc.</li>
                    <li>Currently Inactive websites ( Last Checked on 24 jan, 2023 ) :<br />
                      <a href="http://alinaskarpurhighschool.org">alinaskarpurhighschool.org</a><br />
                      <a href="http://bachamarigkhighschool.org">bachamarigkhighschool.org</a><br />
                      <a href="http://baishnabnagarhs.org">baishnabnagarhs.org</a><br />
                      <a href="http://brightinstitutemalda.com">brightinstitutemalda.com</a><br />
                      <a href="http://chachandabjhighschool.com">chachandabjhighschool.com</a><br />
                      <a href="http://chakuliahighschool.in">chakuliahighschool.in</a><br />
                      <a href="http://englishbazarblock.org">englishbazarblock.org</a><br />
                      <a href="http://gothaarhighschool.org">gothaarhighschool.org</a><br />
                      <a href="http://haripurhighschool.org">haripurhighschool.org</a><br />
                      <a href="http://harirampurasdmhighschool.org">harirampurasdmhighschool.org</a><br />
                      <a href="http://jugaltalahighschool.com">jugaltalahighschool.com</a><br />
                      <a href="http://kalindrihighschool.org">kalindrihighschool.org</a><br />
                      <a href="http://kgcemalda.com">kgcemalda.com</a><br />
                      <a href="http://kksksv.org">kksksv.org</a><br />
                      <a href="http://lalbathanihighschool.org">lalbathanihighschool.org</a><br />
                      <a href="http://matikundahighschool.com">matikundahighschool.com</a><br />
                      <a href="http://milanpallihighschool.com">milanpallihighschool.com</a><br />
                      <a href="http://milkihighschool.org">milkihighschool.org</a><br />
                      <a href="http://nayabazarhighschool.com">nayabazarhighschool.com</a><br />
                      <a href="http://nishindrahighschool.org">nishindrahighschool.org</a><br />
                      <a href="http://nps-e-seva.com">nps-e-seva.com</a><br />
                      <a href="http://patagorahighschool.org">patagorahighschool.org</a><br />
                      <a href="http://ratuahighmadrasah.org">ratuahighmadrasah.org</a><br />
                      <a href="http://rkmmalda.org">rkmmalda.org</a><br />
                      <a href="http://sankarpurmhuhighmadrasah.com">sankarpurmhuhighmadrasah.com</a><br />
                      <a href="http://siksah.com">siksah.com</a><br />
                      <a href="http://sujapurhighschool.org">sujapurhighschool.org</a><br />
                      <a href="http://www.bpmsmalda.org">www.bpmsmalda.org</a><br /></li>
                    <li>Management Softwares : 
                      <br />ProGati / School Management Software: ( PHP , NODE.JS ) <a href="https://app.progatiapp.com/">https://app.progatiapp.com/</a> ( SCRATCH )  [ 30000+ Users ]
                      <br />I worked on complete school management software where I used PHP, Node.Js as Backend. It was my first big project and here I worked in both sections of Frontend & Backend Development. Some very popular modules of progati are Online Examination, Transportation, Inventory, Accounting, Online Admission, Library, Admission, Online Classroom, Communication ( Chatting Zone ), Smart Content Draggable Editor ( Marksheet, ID Card, Certificates & Money Receipts Template ) where I was worked on & some of my best works till now. 
                      <br />Adyna Forest Management Software : ( PHP ) Running Offline
                      <br />Online Lottery Gaming App : (NODE.JS) 
                      <a href="http://newgolden.in/">http://newgolden.in/</a><br />
                      <br />VIDEO CONVERTER / Transcoder APP : (Offline)
                      <br />Screen Recorder App : (Offline)
                    </li>
                    <li>Review Management Software ( Freelance REACT ) <a href="https://appcan.in/">https://appcan.in/</a></li>
                    <li>Online Learning Management Software ( Freelance REACT ) <a href="https://myemaster.com/">https://myemaster.com/</a></li>  
                    <li>Online Learning Management Software ( Android App Flutter ) <a href="https://play.google.com/store/apps/details?id=com.my_e_master">https://play.google.com/store/apps/details?id=com.my_e_master</a></li>  
                    <li>Fraser Coast Maintenance Management App : ( Freelance PHP ) <a href="https://cms.frasercoastmaintenance.com/">https://cms.frasercoastmaintenance.com/</a></li>
                  </ul>
                </div>
                <div className="resume-item">
                  <h4>Creative Mileage Solutions ( Zeetapro Inc. / Logiklu Inc. ) : </h4>
                  <h5>10th February, 2021 to 1st Sept, 2023</h5>
                  <p><em>Senior Software Developer</em></p>
                  <ul>
                    <li>My Media Center / Media Center Management App : <a href="https://app.mymediacentermanager.com/">https://app.mymediacentermanager.com/</a> ( Lead Developer )</li>
                    <li>In Media Center Management App, Also known as MCM App, I am performing as team lead and in my leadership, MCM going with a huge functional change where the UI & UX of the User & Staff Dashboard fully Functionally changed, In New Dashboard UX, Sortable Blocks Added by which client can choose which contents they need in first look. Popup Functionality added in whole app by which from staying one page user no need to redirect for view, edit, pay & so on. In the Reservation Part ( used React.js & Node.js ), users can reserve anything from the availability calendar directly. I build an Mobile App ( used Flutter ) for iOS is in the production phase. I also worked in every payment section where I used Authorized.Net & Square as Payment Gateway.</li>
                    <li>VideoQual / Video Quality Management App : ( PHP ) <a href="https://www.videoqual.com/">https://www.videoqual.com/</a> ( Lead Developer ) </li>
                    <li>As Videoqual I worked on the Subscription Part, Video Transcoding Part & some Settings Part of that product. For payment I used Stripe & Authorized.Net as Payment Gateway, where Card & ACH payment modules were created to receive payment for packages or features & webhook used to check if card charges also known as (silent post) which are pre decided & prorated Saas Payment.</li>
                    <li>Logiklu / Analytical Software : ( PHP ) <a href="https://www.logiklu.com/">https://www.logiklu.com/</a> ( Teammate )</li>
                    <li>Worked on Subscription & Leadform part dedicatedly in LogiKlu where in leadform part, form.io distribution package (React) modified, Some extra functionality added for better UI in Leadform Design & Added Google Recaptcha functionality, As well as Embed Code generated & Saved the data which filled by users in the lead forms for future use. In the Activity Part ( used React.js & Node.js ), users can add activities, calls, and meetings from the calendar directly. In Subscription part I was used Stripe & Authorized.Net as Payment Gateway, where Card & ACH payment modules were created to receive payment for packages or features & webhook used to check if card charged also known as (silent post) which are pre decided & prorated Saas Payment.</li>
                    <li> & so on</li>
                  </ul>
                </div>
                <div className="resume-item">
                  <h4>Freelance: 1st Sept, 2023 to till now. ( Fulltime )</h4>
                  <h5>2017 - 2018</h5>
                  <p><em>Stepping Stone Advertising, New York, NY</em></p>
                  <ul>
                    <li>Website Design ( Freelance REACT ) <a href="https://mytourstays.in/">https://mytourstays.in/</a></li>
                    <li>Laundry Management Software ( Android App React Native - PHP & Node.Js ) Not Published Yet</li>  
                    <li>Complete Ecommerce Platform (Laravel - PHP) : <a href="https://www.kartdesire.com/">https://www.kartdesire.com/</a>.</li>
                    <li>Ecommerce Platform ( Android & iOS App React Native - PHP & Node.Js ) Not Published Yet</li>
                    <li>Complete Food Delivery Platform <a href="https://www.mealchefz.com/">https://www.mealchefz.com/</a></li>
                    <li>Food Delivery Platform ( Android & iOS App React Native - PHP & Node.Js ) Not Published Yet</li>
                    <li>OTT Platform (Web, Android TV & Mobile Friendly) : 
                      <br />CND Play <a href="http://cndplay.com/">http://cndplay.com/</a> (Android & iOS app available in stores) <br />
                      <br />Jaam <a href="https://jaam.tv/">jaam.tv</a> (Android & iOS app available in stores) </li>
                    <li>WordPress News Portal Protidin24, thebengal.in & 12 more</li>
                    <li>Webinar Management System & so on</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </section>

        {/* <section id="portfolio" className="portfolio section-bg">
          <div className="container">

            <div className="section-title">
              <h2>Portfolio</h2>
              <p>I am eager to bring my technical expertise and commitment to excellence to your project. Having successfully collaborated with clients globally, I am confident in my ability to deliver high-quality solutions tailored to your specific needs.</p>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">All</li>
                  <li data-filter=".filter-app">App</li>
                  <li data-filter=".filter-card">Card</li>
                  <li data-filter=".filter-web">Web</li>
                </ul>
              </div>
            </div>

            <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">

              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/assets/img/portfolio/portfolio-1.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img src="assets/assets/img/portfolio/portfolio-2.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 3"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/assets/img/portfolio/portfolio-3.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 2"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/assets/img/portfolio/portfolio-4.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img src="assets/assets/img/portfolio/portfolio-5.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 2"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/assets/img/portfolio/portfolio-6.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 3"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/assets/img/portfolio/portfolio-7.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 1"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/assets/img/portfolio/portfolio-8.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 3"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img src="assets/assets/img/portfolio/portfolio-9.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 3"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section> */}

        {/* <section id="services" className="services">
          <div className="container">

            <div className="section-title">
              <h2>Services</h2>
              <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-briefcase"></i></div>
                <h4 className="title"><a href="">Lorem Ipsum</a></h4>
                <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                <div className="icon"><i className="bi bi-card-checklist"></i></div>
                <h4 className="title"><a href="">Dolor Sitema</a></h4>
                <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                <div className="icon"><i className="bi bi-bar-chart"></i></div>
                <h4 className="title"><a href="">Sed ut perspiciatis</a></h4>
                <p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                <div className="icon"><i className="bi bi-binoculars"></i></div>
                <h4 className="title"><a href="">Magni Dolores</a></h4>
                <p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                <div className="icon"><i className="bi bi-brightness-high"></i></div>
                <h4 className="title"><a href="">Nemo Enim</a></h4>
                <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                <div className="icon"><i className="bi bi-calendar4-week"></i></div>
                <h4 className="title"><a href="">Eiusmod Tempor</a></h4>
                <p className="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
              </div>
            </div>

          </div>
        </section> */}

        {/* <section id="testimonials" className="testimonials section-bg">
          <div className="container">

            <div className="section-title">
              <h2>Testimonials</h2>
              <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
            </div>

            <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
              <div className="swiper-wrapper">

                <div className="swiper-slide">
                  <div className="testimonial-item" data-aos="fade-up">
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img src="assets/assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="" />
                    <h3>Saul Goodman</h3>
                    <h4>Ceo &amp; Founder</h4>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item" data-aos="fade-up" data-aos-delay="100">
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img src="assets/assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item" data-aos="fade-up" data-aos-delay="200">
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img src="assets/assets/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt="" />
                    <h3>Jena Karlis</h3>
                    <h4>Store Owner</h4>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item" data-aos="fade-up" data-aos-delay="300">
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img src="assets/assets/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt="" />
                    <h3>Matt Brandon</h3>
                    <h4>Freelancer</h4>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item" data-aos="fade-up" data-aos-delay="400">
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img src="assets/assets/img/testimonials/testimonials-5.jpg" className="testimonial-img" alt="" />
                    <h3>John Larson</h3>
                    <h4>Entrepreneur</h4>
                  </div>
                </div>

              </div>
              <div className="swiper-pagination"></div>
            </div>

          </div>
        </section> */}

        <section id="contact" className="contact">
          <div className="container">

            <div className="section-title">
              <h2>Contact</h2>
              <p>Feel free to contact me.</p>
            </div>

            <div className="row" data-aos="fade-in">

              <div className="col-lg-5 d-flex align-items-stretch">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Near Dominos, Majherpara, Kolkata, 700102</p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>sandip@saasproai.com</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+91 863 7054 652</p>
                  </div>

                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe> */}
                </div>

              </div>

              <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Your Name</label>
                      <input type="text" name="name" className="form-control" id="name" required />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Your Email</label>
                      <input type="email" className="form-control" name="email" id="email" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Subject</label>
                    <input type="text" className="form-control" name="subject" id="subject" required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Message</label>
                    <textarea className="form-control" name="message" rows="10" required></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Your message has been sent. Thank you!</div>
                  </div>
                  <div className="text-center"><button type="submit">Send Message</button></div>
                </form>
              </div>

            </div>

          </div>
        </section>

      </main>

      <footer id="footer">
        <div className="container">
          <div className="copyright">
            &copy; Copyright <strong><span>Sandip Kundu</span></strong>
          </div>
          <div className="credits">
            Designed by <a href="https://saasproai.com/">Sandip Kundu</a>
          </div>
        </div>
      </footer>

      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

    </>
  );
}

export default App;
